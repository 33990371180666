import { createWebHistory, createRouter } from "vue-router";

import NewArtist from "@/views/NewArtist.vue";
import NewArtwork from "@/views/NewArtwork.vue";

import DeleteArtwork from "@/views/DeleteArtwork.vue";
import DeleteArtist from "@/views/DeleteArtist.vue";

import PatchArtist from "@/views/PatchArtist.vue";

import ArtistsList from "@/views/ArtistsList.vue";
import ArtworksList from "@/views/ArtworksList.vue";

import LoginLogout from "@/views/LoginLogout.vue";

import ArtworksPre from "@/views/ArtworksPre.vue";
import ArtworksDemo from "@/views/ArtworksDemo";



const routes = [
  {
    path: "/ArtworksDemo",
    name: "ArtworksDemo",
    component: ArtworksDemo,

  },

  {
    path: "/ArtworksPre",
    name: "ArtworksPre",
    component: ArtworksPre,

  },

  {
    path: "/NewArtist",
    name: "NewArtist",
    component: NewArtist,
  },
  {
    path: "/NewArtwork",
    name: "NewArtwork",
    component: NewArtwork,
  },

  {
    path: "/DeleteArtwork",
    name: "DeleteArtwork",
    component: DeleteArtwork,
  },
  {
    path: "/DeleteArtist",
    name: "DeleteArtist",
    component: DeleteArtist,
  },

  {
    path: "/PatchArtist",
    name: "PatchArtist",
    component: PatchArtist,
  },
  
  {
    path: "/ArtistsList",
    name: "ArtistsList",
    component: ArtistsList,
  },
  {
    path: "/ArtworksList",
    name: "ArtworksList",
    component: ArtworksList,
  },

  {
    path: "/",
    name: "LoginLogout",
    component: LoginLogout,
  },
  {
    path: "/LoginLogout",
    name: "LoginLogout",
    component: LoginLogout,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

router.beforeEach((to, from, next) => {
  if (to.name !== 'LoginLogout' && JSON.parse(localStorage.getItem("loggedInStatus")) !== "true") next({ name: 'LoginLogout' }), console.log("PLEASE LOG IN!");
  else next()
});