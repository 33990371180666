<template>
  <div class="viewsContainer" :key="artistKey">
    <div class="viewsContainer2">
      <div class="card">

        <div class="card-body">
  <div id="artist">
    <carousel-3d :controls-visible="true" :clickable="false" :perspective="0">
      <slide v-for="(slide, i) in posts" :key="i" :index="i">

        <figure >
          <div class="form-check form_active">
              <input class="form-check-input" type="checkbox" value="" v-bind:checked="slide.active" v-bind:id="slide.title" v-on:change="SetStatusArtist(slide)">
              <label class="form-check-label">
              Active
              </label>
            </div>
<!--          <img src="https:">-->
          <div class="to_right">
          <h5 class="card-title ">{{ slide.name }}</h5>
<!--          <h6 class="card-subtitle mb-2 text-muted" style="padding: 1em 20% 2em 20%;">{{ slide.cover }}</h6>-->
          <p class="card-text bio_text">{{ slide.biography }}</p>
          <p class="card-text small_txt" style="text-align: left;">
            <i class="bi bi-globe small_txt"></i> <a :href="slide.website" class="card-link small_txt">{{ slide.website }}</a><br>
            <i class="bi bi-instagram small_txt"></i> <a :href="slide.instagram" class="card-link small_txt">{{ slide.instagram }}</a><br>
            <i class="bi bi-twitter small_txt"></i> <a :href="slide.twitter" class="card-link small_txt">{{ slide.twitter }}</a>
          </p>
          </div>

<!--          <h5>{{ slide.artworks.title }}</h5>-->
          <figcaption class="to_left" v-on:click="get_artwork(slide.artworks)">{{ slide.cover }}</figcaption>
        </figure>
      </slide>
    </carousel-3d>
  </div>
    </div>
      </div>
    </div>
  </div>

  <div class="viewsContainer" :key="artworkKey">
    <div class="viewsContainer2">
      <div class="card">

        <div class="card-body">
  <div id="art">
    <carousel-3d :controls-visible="true" :clickable="true"  :perspective="0">
      <slide v-for="(slide, i) in artworksin" :key="i" :index="i">
        <figure>
<!--          <img src="https:">-->
           <img class="vie_back" src="" v-bind:id="slide.title" style="height:200px" alt="not_lol">
            <div class="form-check form_active">
              <input class="form-check-input" v-bind:checked="slide.active"
                     type="checkbox" value="" v-bind:id="slide.title" v-on:change="SetStatus(slide)">
              <label class="form-check-label">
              Active
              </label>
            </div>
          <button id="logButton" class="btn btn-primary thi" v-if="image_get(slide.image, slide.title)" style="">Log</button>
          <div class="to_right">
          <h5 class="card-title small_txt">{{ slide.description }}</h5>

<!--          <h6 class="card-subtitle mb-2 text-muted" style="padding: 1em 20% 2em 20%;">{{ slide.cover }}</h6>-->
<!--              <p class="card-text" style="text-align: justify; padding-bottom: 1em;">{{ slide.description }}</p>-->
<!--          <p class="card-text" style="text-align: left; padding-left: 25%">-->
<!--            <i class="bi bi-globe"></i> <a :href="slide.website" class="card-link">{{ slide.website }}</a><br>-->
<!--            <i class="bi bi-instagram"></i> <a :href="slide.instagram" class="card-link">{{ slide.instagram }}</a><br>-->
<!--            <i class="bi bi-twitter"></i> <a :href="slide.twitter" class="card-link">{{ slide.twitter }}</a>-->
<!--              </p>-->
          <h5>{{ slide.medium }}</h5>
          </div>
          <figcaption >{{ slide.title }}</figcaption>

        </figure>
      </slide>
    </carousel-3d>
  </div>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue3-carousel-3d';
import axios from "axios";
import {path} from "@/_helpers/basepath";


export default {
  name: "ART",
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {
      posts: [],
      artworksin: [],
      artistKey: 0,
      artworkKey: 0,
    }
  },
    mounted() {

    this.get_data()
    this.fresh()

  },

  methods: {

    get_data(){
      var usrname = JSON.parse(localStorage.getItem("storedUserName"));
      var usrpass = JSON.parse(localStorage.getItem("storedUserPass"));

    axios
      .get(path() + '/api/art/', {
        auth: {

          username: usrname,
          password: usrpass,
        }
      },
      ).then((response) => {
        let li = response.data;
        let li_new = []
        for (let e in li ){
          if (li[e]['deleted'] === false){
            console.log(li[e]);
            //delete li[e]
            li_new.push(li[e])
          }
        }
        console.log('new li: ', li_new)
        //this.posts = response.data;
        this.posts = li_new;


      })

    },

   async image_get(url, id) {
      var usrname = JSON.parse(localStorage.getItem("storedUserName"));
      var usrpass = JSON.parse(localStorage.getItem("storedUserPass"));

      axios
        .get(url, {
          responseType: "blob",
          auth: {
            username: usrname,
            password: usrpass,
          }
        },
        ).then(function (response) {
          var reader = new window.FileReader();
          reader.readAsDataURL(response.data);
          console.log("date_n: ", reader)
          reader.onload = function () {
            let imageDataUrl = reader.result;


            console.log(id)
            document.getElementById(id).setAttribute("src", imageDataUrl);
          }
          return {}
        }).catch(err => {
          console.log("WRONG CREDENTIALS - USER NOT LOGGED IN! NO IMAGE WILL BE SHOWN! :( - " + err);
        });
    },

    async get_artwork(list) {
      let list_new = [];
      for (let e in list){
        if (list[e]['deleted'] === false){
          list_new.push(list[e])

        }
      }
      this.artworksin = list_new;
      console.log('click', this.artworksin);
      this.ArtworkUpdate();


    },
    ArtworkUpdate(){
      this.artworkKey += 1;

    },

    ArtistSet(){
     this.artistKey += 1;
    },

    async fresh() {
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log('Artists set');
      this.artistKey += 1;
    },

    SetReq(artwork_title, status){
     let userName = JSON.parse(localStorage.getItem("storedUserName"));
     let userPass = JSON.parse(localStorage.getItem("storedUserPass"));

      axios.post(path() + '/api/artwork_active',

        {title: artwork_title, active: status},

        {
          auth: {
            username: userName,
            password: userPass,

          }
        }
      )
        .then(response => {
          if (response.status === 200) {
            console.log("Response: ", response.data.message);
          }
          else {
            console.log("artwork not activated: ", artwork_title);
          }
        })
        .catch(err => {
          console.log("error - " + err, artwork_title);
        });
    },

    SetReqArtist(artist_name, status){
      let userName = JSON.parse(localStorage.getItem("storedUserName"));
     let userPass = JSON.parse(localStorage.getItem("storedUserPass"));

      axios.post(path() + '/api/artist_active',

        {name: artist_name, active: status},

        {
          auth: {
            username: userName,
            password: userPass,

          }
        }
      )
        .then(response => {
          if (response.status === 200) {
            console.log("Response: ", response.data.message);
          }
          else {
            console.log("artist not activated: ", artist_name);
          }
        })
        .catch(err => {
          console.log("error - " + err, artist_name);
        });

    },

    SetStatus(s){
     console.log('status set', s.active);
      if (s.active === true) {
        console.log('status true', s.active, s.title)
        this.SetReq(s.title, false)
      }
      if (s.active === false){
        console.log('status false', s.active, s.title)
        this.SetReq(s.title, true)
      }
      this.get_data()

    },

    SetStatusArtist(s){
      console.log('set status artist', s.active);
      if (s.active === true) {
        console.log('status true', s.active, s.name)
        this.SetReqArtist(s.name, false)
      }
      if (s.active === false){
        console.log('status false', s.active, s.name)
        this.SetReqArtist(s.name, true)
      }
      this.get_data()
    }

  },

};
</script>

<style scoped>
.carousel-3d-container .current figure {
  margin:0;
}

.carousel-3d-container .carousel-3d-slide.current figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 15px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
}
.viewsContainer {
  max-width: 2000px;

  align-self: center;
}
.card{
  width: 1000px;
  background-color: rgba(0, 0, 0, 1);
  align-self: center;
}
.viewsContainer2 {
    max-width: 1000px;

    align-self: center;
}
.thi{
  display: none;
}
.vie_back{
  min-height: auto;
  min-width: 100%;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  z-index: -3;
  top: 0;
  left: 0;
}
.form_active{
  padding-right: 5%;
  padding-left: 85%;
}
.bio_text{
  text-align: -moz-left;
  padding-bottom: 1em;
  font-size: xx-small;
}
.to_right{
  padding: 1em;
}
.small_txt{
  font-size: xx-small;

}


</style>