import axios from 'axios'
import { alert } from "@/views/_helpers/bootstrapAlert";
import { path } from "@/_helpers/basepath";

export function create_artwork() {

  console.log("createNewArtist() START :)");

  let userName = JSON.parse(localStorage.getItem("storedUserName"));
  let userPass = JSON.parse(localStorage.getItem("storedUserPass"));

  // let artistName = document.getElementById("newArtistName").value;
  let selectArtist = document.querySelector('#chooseArtist');
  let artistName = selectArtist.value;
  let selectSecondaryArtist = document.querySelector('#chooseSecondaryArtist');
  let secondaryArtistName = selectSecondaryArtist.value;

  let artworkTitle = document.getElementById("newArtworkTitle").value;

  let videofile = document.querySelector('#newVideo')
  let artworkVideo = videofile.files[0].name;

  let imagefile = document.querySelector('#newImage');
  let artworkImage = imagefile.files[0].name;

  let artworkMedium = document.getElementById("newArtworkMedium").value;
  let artworkYear = document.getElementById("newArtworkYear").value;
  let artworkDuration = document.getElementById("newArtworkDuration").value;
  let artworkCourtesy = document.getElementById("newArtworkCourtesy").value;
  let artworkDescription = document.getElementById("newArtworkDescription").value;

  axios.post(
    path() + '/api/artwork/',

    { title: artworkTitle, artist: artistName, secondary_artist: secondaryArtistName, video: artworkVideo, image: artworkImage, medium: artworkMedium, year: artworkYear, duration: artworkDuration, courtesy: artworkCourtesy, description: artworkDescription },

    {
      auth: {
        username: userName,
        password: userPass,

      }
    }
  )
    .then(response => {
      if (response.status === 201) {
        console.log("201 proceed...", artistName);
        alert('Server Response:<br><b>' + response.status + ': ' + response.data.message + '<b>', 'success', 'info-circle-fill');
      }
    }).catch(err => {
      console.log("new artist failed :( - " + err, artistName, err.response.data.message);
      alert('Server Response:<br><b>' + err.status + ': ' + err.response.data.message + '<b>', 'danger', 'exclamation-triangle-fill');
    });
}