import axios from 'axios'
import { alert } from "@/views/_helpers/bootstrapAlert";
import { path } from "@/_helpers/basepath";

export async function upload_picture() {
    let ret = false;
    let userName = JSON.parse(localStorage.getItem("storedUserName"));
    let userPass = JSON.parse(localStorage.getItem("storedUserPass"));

    const config = { headers: { 'Content-Type': 'multipart/form-data' } }
    const data = new FormData(document.getElementById('uploadForm'))
    var imagefile = document.querySelector('#newImage')
    try {
        console.log('UPLOAD PICTURE', imagefile.files[0])
        data.append('file', imagefile.files[0])
        data.append('filename', imagefile.files[0].name)

    } catch (error){
        console.log('NO PICTURE')
    }


    await axios.post(path() + '/api/upload/img', data,
        {
            auth: {
                username: userName,
                password: userPass,
            }
        },
        config,
    )
        .then(response => {
            if (response.status === 200) {
                console.log('RESPONSE DATA: ' + response.data)
                console.log("Response: ", response.data.message);
                //document.getElementById("newArtworkImageName").value = imagefile.files[0].name
                // alert('Artist created: ' + imagefile.files[0].name, 'success', 'bi-check-circle-fill');
                alert('Server Response:<br><b>' + response.status + ': ' + response.data.message + '<b>', 'success', 'bi-check-circle-fill');
                ret = true;

            }

        }).catch(err => {
            console.log("ERROR RESPONSE DATA MESSAGE: ", err.response.data.message);
            // alert('Artist already exists!', 'danger', 'exclamation-triangle-fill');
            alert('Server Response:<br><b>' + err.status + ': ' + err.response.data.message + '<b>', 'danger', 'exclamation-triangle-fill');
        })
    return ret ;
}