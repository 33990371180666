import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-icons/font/bootstrap-icons.css'


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Carousel3d from 'vue3-carousel-3d';
import "vue3-carousel-3d/dist/index.css"





// <---


// createApp(App).mount('#app') // <---
createApp(App).use(router, Carousel3d).mount('#app') // <---

