<template>

  <div class="viewsContainer">
    <div class="viewsContainer2">

      <h1 style="color:#146a90;">Patch Artist</h1>

      <select id="chooseArtist" class="form-select" aria-label="Default select example" style="margin-bottom: 2em;">
        <option selected>Choose Artist to patch</option>
        <!-- <option v-for="post in posts" :key="post.id" value="post.name">{{ post.name }}</option> -->
        <option v-for="post in posts" :key="post.id" v-bind:value="post.name">{{ post.name }}</option>
      </select>

      <button class="btn btn-primary" v-on:click="getArtistByName3()">Choose Artist</button>

      <h1 style="color:#146a90;">Preview</h1>

      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ posts.name }}</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="padding: 1em 20% 2em 20%;">{{ posts.cover }}</h6>
          <p class="card-text" style="text-align: justify; padding-bottom: 1em;">{{ posts.biography }}</p>
          <p class="card-text" style="text-align: left; padding-left: 25%">
            <i class="bi bi-globe"></i> <a :href="posts.website" class="card-link">{{ posts.website }}</a><br>
            <i class="bi bi-instagram"></i> <a :href="posts.instagram" class="card-link">{{ posts.instagram }}</a><br>
            <i class="bi bi-twitter"></i> <a :href="posts.instagram" class="card-link">{{ posts.twitter }}</a>
          </p>
          <!-- <h5>{{ posts.artworks.title }}</h5> -->
        </div>
      </div>



      <h1 style="color:#146a90;">Patch</h1>

      <div class="card">
        <div class="card-body">
<!--          <form id="form1" @submit.prevent="submit" >-->
          <form id="form1" name="form1">

          <h5 class="card-title" style="text-align: left">Name</h5>
          <div id="patchArtistName" class="input-group mb-3">
            <input id="searchArtistName" type="text" class="form-control" :value="posts.name">
          </div>

          <h5 class="card-title" style="text-align: left">Quote</h5>
          <div id="patchArtistCover" class="input-group mb-3">
            <input id="searchArtistCover" type="text" class="form-control" :value="posts.cover">
          </div>

          <h5 class="card-title" style="text-align: left">Biography</h5>

          <div id="patchArtistBio" class="input-group mb-3">
            <textarea id="searchArtistBio" type="text" class="form-control" aria-label="Biography"
              aria-describedby="basic-addon1" :value="posts.biography"></textarea>
          </div>

          <h5 class="card-title" style="text-align: left">Website</h5>
          <div id="patchArtistWebsite" class="input-group mb-3">
            <input id="searchArtistWebsite" type="text" class="form-control" :value="posts.website">
          </div>

          <h5 class="card-title" style="text-align: left">Instagram</h5>
          <div id="patchArtistInsta" class="input-group mb-3">
            <input id="searchArtistInsta" type="text" class="form-control" :value="posts.instagram">
          </div>

          <h5 class="card-title" style="text-align: left">Twitter</h5>
          <div id="patchArtistTwitter" class="input-group mb-3">
            <input id="searchArtistTwitter" type="text" class="form-control" :value="posts.twitter">
          </div>
<!--          </div>-->
        </form>


          <div id="liveAlertPlaceholder"></div>

          <button type="submit" class="btn btn-primary" v-on:click="createChange()">Patch</button>
          <button type="submit" class="btn btn-primary" v-on:click="reset()" style="margin: 10px">Reset</button>

        </div>
      </div>



    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { alert } from "@/views/_helpers/bootstrapAlert";
import {path} from "@/_helpers/basepath";

export default {
  data() {
    return {
      posts: [],
    }
  },

  async mounted() {

    var usrname = JSON.parse(localStorage.getItem("storedUserName"));
    var usrpass = JSON.parse(localStorage.getItem("storedUserPass"));

    await axios

      .get(path() + '/api/art/', {
        auth: {

          username: usrname,
          password: usrpass,
        }
      },
        // console.log('Authenticated'),
      ).then((response) => {

        this.posts = response.data
        console.log('resp data: ', this.posts)

      })
  },


  methods: {
    async getArtistByName3() {
      let userName = JSON.parse(localStorage.getItem("storedUserName"));
      let userPass = JSON.parse(localStorage.getItem("storedUserPass"));
      // let artistName = document.getElementById("searchArtistNameForm").value;
      let selectElement = document.querySelector('#chooseArtist');
      // let output = selectElement.value;
      let artistName = selectElement.value
      console.log("Artist to patch is " + artistName);

      let url = path() + "/api/artist/";

      await axios.get(url,

        {

          params: { name: artistName }, // data: beim POST https://svbeta.ventus.network/api/artist/
          auth: {
            username: userName,
            password: userPass,

          },

        },
      )
        .then(response => {
          if (response.status === 200) {
            //proceed...
            console.log("200 proceed...", userName);

            this.posts = response.data;
            console.log("ArtistName Test:", artistName);
            this.info = response;

          }
        }).catch(err => {
          console.log("new artist failed :( - " + err);
        });
    },
    createChange() {
      console.log("createNewArtist() START :)");

      let userName = JSON.parse(localStorage.getItem("storedUserName"));
      let userPass = JSON.parse(localStorage.getItem("storedUserPass"));
      let artistName = document.getElementById("searchArtistName").value;
      let artistCover = document.getElementById("searchArtistCover").value;
      let artistBiography = document.getElementById("searchArtistBio").value;
      let artistWebsite = document.getElementById("searchArtistWebsite").value;
      let artistInstagram = document.getElementById("searchArtistInsta").value;
      let artistTwitter = document.getElementById("searchArtistTwitter").value;

      axios.post(
        path() + '/api/artist/update_artist',

        { name: artistName, cover: artistCover, biography: artistBiography, website: artistWebsite, instagram: artistInstagram, twitter: artistTwitter },

        {
          auth: {
            username: userName,
            password: userPass,

          }
        }
      )
        .then(response => {
          if (response.status === 201) {
            //proceed...
            console.log("201 proceed...", artistName, artistCover);
            alert('Artist changed: ' + artistName, 'secondary', 'info-circle-fill');
            window.setTimeout(function() { document.form1.submit(); }, 5000);
          }
        }).catch(err => {
          console.log("new artist failed " + err, artistName);
          alert('failed: '+ err.response.data.message, 'danger', 'exclamation-triangle-fill');
        });
    },
    reset(){
      document.form1.submit()

    },

  }, // END METHODS
}
</script>