<template>

  <div class="viewsContainer">
    <div class="viewsContainer2">

      <h1 style="color:#782175;">Artist List</h1>

      <div class="card" v-for="post in posts" :key="post.id" style="margin-bottom: 2em;">
        <div class="card-body">
          <h5 class="card-title">{{ post.name }}</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="padding: 1em 20% 2em 20%;">{{ post.cover }}</h6>
          <p class="card-text" style="text-align: justify; padding-bottom: 1em;">{{ post.biography }}</p>
          <p class="card-text" style="text-align: left; padding-left: 25%">
            <i class="bi bi-globe"></i> <a :href="post.website" class="card-link">{{ post.website }}</a><br>
            <i class="bi bi-instagram"></i> <a :href="post.instagram" class="card-link">{{ post.instagram }}</a><br>
            <i class="bi bi-twitter"></i> <a :href="post.twitter" class="card-link">{{ post.twitter }}</a>
          </p>
          <h5>{{ post.artworks.title }}</h5>
        </div>
      </div>

    </div>
  </div>

</template>


<script>
import axios from 'axios'
import { path } from "@/_helpers/basepath";


export default {
  data() {
    return {
      posts: []
    }
  },
  mounted() {

    var usrname = JSON.parse(localStorage.getItem("storedUserName"));
    var usrpass = JSON.parse(localStorage.getItem("storedUserPass"));

    axios
      .get(path() + '/api/art/', {
        auth: {

          username: usrname,
          password: usrpass,
        }
      },
        console.log('Authenticated'),
      ).then((response) => {

        this.posts = response.data


      })
  }
}




</script>