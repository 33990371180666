<template>

  <div class="viewsContainer">
    <div class="viewsContainer2">

      <h1 style="color:#146a90;">Login/Logout</h1>
      <h6 class="svVersionNumber">v20230118-1927</h6>


      <div class="card">
        <div class="card-body">


          <table class="table" style="text-align:left">
            <tr>
              <th scope="row">Username</th>
              <td><a id="showUserName"></a></td>
              <!--          <td><a id="showUserPass"></a></td>-->
            </tr>
            <tr>
              <th scope="row">Role</th>
              <td colspan="2"><a id="showUserRole"></a></td>
            </tr>
          </table>

          <div id="logInForm">

            <div class="mb-3 mt-3">
              <div class="form-group">
                <h5 class="card-title-SVForm">Username:</h5>
                <input id="userName" class="form-control" placeholder="Enter Username" type="text">
              </div>
            </div>

            <div class="mb-3 mt-3">
              <div class="form-group">
                <h5 class="card-title-SVForm">Password:</h5>
                <input id="userPass" class="form-control" placeholder="Enter Password" type="password">
              </div>
            </div>

          </div>

          <div id="liveAlertPlaceholder"></div>

          <div id="loginButton" class="mb-3 mt-3">
            <div class="form-group">
              <button class="btn btn-primary" v-on:click="login()">Login</button>
            </div>
          </div>

          <div id="logOutButton" class="mb-3 mt-3">
            <div class="form-group">
              <button class="btn btn-primary" v-on:click="logOut()">Log Out</button>
            </div>
          </div>


        </div>
      </div>


    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { alert } from './_helpers/bootstrapAlert';
import { path } from "@/_helpers/basepath";

export default {

  data() {
    return {
      userName: 0,
      userPass: 0,
      posts: [],
    };
  },

  mounted() {
    document.getElementById("showUserName").innerHTML = JSON.parse(localStorage.getItem("storedUserName"));
    //document.getElementById("showUserPass").innerHTML = JSON.parse(localStorage.getItem("storedUserPass"));
    document.getElementById("showUserRole").innerHTML = JSON.parse(localStorage.getItem("storedUserRole"));

    if (JSON.parse(localStorage.getItem("loggedInStatus")) === "true") {
      //proceed...
      console.log("USER ALREADY LOGGED IN");
      // alert('You are logged in 11111!', 'success');

      // document.getElementById("logOutButton").style.visibility = "visible";
      // document.getElementById("logInForm").style.visibility = "hidden";
      document.getElementById("logOutButton").style.display = "block";
      document.getElementById("logInForm").style.display = "none";
      document.getElementById("loginButton").style.display = "none";
    }
    else {
      // throw error and go to catch block
      console.log("USER NOT LOGGED IN");
      // alert('Your are not logged in 222222!', 'danger');

      // document.getElementById("logOutButton").style.visibility = "hidden";
      // document.getElementById("logInForm").style.visibility = "visible";
      document.getElementById("logOutButton").style.display = "none";
      document.getElementById("logInForm").style.display = "block";
      document.getElementById("loginButton").style.display = "block";
    }
  },


  methods: {


    logOut() {
      localStorage.removeItem('storedUserName');
      localStorage.removeItem('storedUserPass');
      localStorage.removeItem('storedUserRole');
      localStorage.setItem("loggedInStatus", JSON.stringify("false"));
      document.getElementById("showUserName").innerHTML = JSON.parse(localStorage.getItem("storedUserName"));
      // document.getElementById("showUserPass").innerHTML = JSON.parse(localStorage.getItem("storedUserPass"));
      document.getElementById("showUserRole").innerHTML = JSON.parse(localStorage.getItem("storedUserRole"));
      console.log("Login credentials removed from Local Storage! - USER LOGGED OUT :)");


      // document.getElementById("logOutButton").style.visibility = "hidden";
      // document.getElementById("logInForm").style.visibility = "visible";
      document.getElementById("logOutButton").style.display = "none";
      document.getElementById("logInForm").style.display = "block";
      document.getElementById("loginButton").style.display = "block";

      alert('Successfully logged out :)', 'success', 'check-circle-fill'); // Bootstrap Alert
    },

    login() {
      console.log("checkResponse3 START :)");

      let userName = document.getElementById("userName").value;
      let userPass = document.getElementById("userPass").value;

      console.log("checkResponse3 CHECK");

      axios.get(
        path() + '/api/has_role',
        {
          auth: {
            username: userName,
            password: userPass,
          },
          email: userName,
        },

      )
        .then(response => {
          if (response.status === 200) {
            console.log("proceed...", userName);
            localStorage.setItem("storedUserName", JSON.stringify(userName));
            localStorage.setItem("storedUserPass", JSON.stringify(userPass));
            // loggedInStatus -> TRUE
            localStorage.setItem("loggedInStatus", JSON.stringify("true"));
            console.log("USER LOGGED IN SUCCESSFULLY! :)");
            document.getElementById("userName").value = '';
            document.getElementById("userPass").value = '';
            document.getElementById("showUserName").innerHTML = JSON.parse(localStorage.getItem("storedUserName"));
            // document.getElementById("showUserPass").innerHTML = JSON.parse(localStorage.getItem("storedUserPass"));

            // document.getElementById("logOutButton").style.visibility = "visible";
            // document.getElementById("logInForm").style.visibility = "hidden";
            document.getElementById("logOutButton").style.display = "block";
            document.getElementById("logInForm").style.display = "none";
            document.getElementById("loginButton").style.display = "none";

            alert('Login successful', 'success', 'check-circle-fill'); // Bootstrap Alert
            this.posts = response.data;
            localStorage.setItem("storedUserRole", JSON.stringify(response.data.role));
            console.log("LOGGED IN AS: " + response.data.role);
            alert('Logged in as ' + response.data.role, 'secondary', 'info-circle-fill'); // Bootstrap Alert
            document.getElementById("showUserRole").innerText = JSON.parse(localStorage.getItem("storedUserRole"));
          }
        }).catch(err => {
          console.log("WRONG CREDENTIALS - USER NOT LOGGED IN! :( - " + err);
          alert('Login failed :(', 'danger', 'exclamation-triangle-fill'); // Bootstrap Alert
        });
    },


  },

}
</script>
