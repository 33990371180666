// Bootstrap Alert Messages
// https://getbootstrap.com/docs/5.1/components/alerts/
export function alert(message, type, icon) {
  var alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  var wrapper = document.createElement('div')
  wrapper.innerHTML = '<div id="alertBox" class="alert alert-' + type + ' alert-dismissible fade show" role="alert"><i class="bi flex-shrink-0 me-2 bi-' + icon + '"></i>' + message + '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'

  // Icons:   bi-check-circle-fill
  //          bi-exclamation-triangle-fill
  //          bi-info-circle-fill

  // Alert Box styles:
  //                    alert-danger
  //                    alert-success
  //                    alert-secondary
  alertPlaceholder.append(wrapper)
  console.log("function alert from bootsrapAlert.js WAS CALLED");

const dismissElement = document.getElementsByClassName("alert")


setTimeout(() => {
  for (let e in dismissElement){
    if (dismissElement[e].className !== undefined){
      dismissElement[e].remove()
    }
  }
}, 5000)


}