<template>

  <div class="viewsContainer">
    <div class="viewsContainer2">

      <h1 style="color:#29008a;">Artworks List</h1>

      <div class="card" v-for="post in posts" :key="post.id" style="margin-bottom: 2em;">
        <!-- <h5 class="card-title" style="color:red; font-size: .8em;">{{ post.artworks }}</h5> -->
        <h5 class="card-title">{{ post.name }}</h5>

        <div class="card-body" v-for="artwork in post.artworks" :key="artwork.title" style="margin-bottom: 2em;">

          <h6 class="card-subtitle mb-2 text-muted">{{ artwork.title }}</h6>
          <!--      <img :src="artwork.image" style="height:200px">-->
          <img src="" v-bind:id="artwork.title" style="height:200px" alt="not_lol">
          <button id="logButton" class="btn btn-primary" v-if="image_get(artwork.image, artwork.title)"
            style="">Log</button>

          <br><br>
          <a class="SVartworkInfo">Courtesy: {{ artwork.courtesy }}</a><br>
          <a class="SVartworkInfo">Duration: {{ artwork.duration }}</a><br>
          <a class="SVartworkInfo">Medium: {{ artwork.medium }}</a><br>
          <a class="SVartworkInfo">Year: {{ artwork.year }}</a><br>
          <a class="SVartworkInfoVideoLink" :href="artwork.video">{{ artwork.video }}</a><br><br>

          <p class="card-text" style="text-align: justify; padding-bottom: 1em;">{{ artwork.description }}</p>

        </div>

      </div>

    </div>
  </div>

</template>


<script>
import axios from 'axios'
import { path } from "@/_helpers/basepath";

export default {

  data() {
    return {
      posts: []
    }
  },

  mounted() {

    var usrname = JSON.parse(localStorage.getItem("storedUserName"));
    var usrpass = JSON.parse(localStorage.getItem("storedUserPass"));
    axios
      .get(path() + '/api/art/', {
        auth: {
          username: usrname,
          password: usrpass,
        }
      }).then((response) => {
        this.posts = response.data
      }
      )

  },

  methods: {

    image_get(url, id) {
      var usrname = JSON.parse(localStorage.getItem("storedUserName"));
      var usrpass = JSON.parse(localStorage.getItem("storedUserPass"));

      axios
        .get(url, {
          responseType: "blob",
          auth: {
            username: usrname,
            password: usrpass,
          }
        },
        ).then(function (response) {
          var reader = new window.FileReader();
          reader.readAsDataURL(response.data);
          console.log("date_n: ", reader)
          reader.onload = function () {
            let imageDataUrl = reader.result;

            console.log(id)
            document.getElementById(id).setAttribute("src", imageDataUrl);
          }
          return {}
        }).catch(err => {
          console.log("WRONG CREDENTIALS - USER NOT LOGGED IN! NO IMAGE WILL BE SHOWN! :( - " + err);
        });
    },

  },


}
</script>