<template>

  <div class="viewsContainer">
    <div class="viewsContainer2">

      <h1 style="color:#146a90;">Delete Artwork</h1>

      <div class="card">
        <div class="card-body">

          <h5 class="card-title" style="text-align: left">Title of Artwork to delete</h5>
          <div class="input-group mb-3">
            <input id="deleteArtworkTitle" type="text" class="form-control">
          </div>

          <h5 class="card-title" style="text-align: left">Artist Name of Artwork to delete</h5>
          <div class="input-group mb-3">
            <input id="deleteArtworkArtist" type="text" class="form-control">
          </div>
          

          <button class="btn btn-primary" v-on:click="deleteArtwork()">Delete Artwork</button>

        <div id="liveAlertPlaceholder"></div>
          <!-- <h5>{{ posts.artworks.title }}</h5> -->
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import {alert} from "@/views/_helpers/bootstrapAlert";
import {path} from "@/_helpers/basepath";


export default {
  data() {
    return {
      posts: [],
    }
  },
  mounted() {



  },


  methods: {

    deleteArtwork() {
      console.log("createNewArtist() START :)");

      let userName = JSON.parse(localStorage.getItem("storedUserName"));
      let userPass = JSON.parse(localStorage.getItem("storedUserPass"));
      let artworkTitle = document.getElementById("deleteArtworkTitle").value;
      let artworkArtist = document.getElementById("deleteArtworkArtist").value;

      axios.post(
          path() + '/api/artwork/delete_artwork',

        { title: artworkTitle, artist: artworkArtist},

          {auth: {
            username: userName,
            password: userPass,

          }}
      )
        .then(response => {
          if (response.status === 201) {
            //proceed...
            console.log("201 proceed...");
            console.log("201 proceed...", artworkTitle);
            alert('Artwork deleted: ' + artworkTitle, 'secondary', 'info-circle-fill');
          }
        }).catch(err => {
          console.log("new artist failed :( - " + err, artworkTitle);
          alert('Artwork do not Exists :(', 'danger', 'exclamation-triangle-fill');
        });
    },



  }, // END METHODS


}


</script>