<template>

  <div id="navWrapper">


  <div id="nav">
    <ul>

        <li class="addContent"><router-link to="/NewArtist"><i class="bi bi-person-circle"></i> New Artist</router-link></li>
        <li class="addContent"><router-link to="/NewArtwork"><i class="bi bi-person-circle"></i> New Artwork</router-link></li>

        <li class="contentManagement"><router-link to="/DeleteArtwork"><i class="bi bi-person-circle"></i> Delete Artwork</router-link></li>
        <li class="contentManagement"><router-link to="/DeleteArtist"><i class="bi bi-person-circle"></i> Delete Artist</router-link></li>

        <li class="ticketManagement"><router-link to="/PatchArtist"><i class="bi bi-person-circle"></i> Patch Artist</router-link></li>

        <li class="userAccManagement"><router-link to="/ArtistsList"><i class="bi bi-ticket-perforated"></i> Artists List</router-link></li>
        <li class="userAccManagement"><router-link to="/ArtworksList"><i class="bi bi-people-fill"></i> Artworks List</router-link></li>

        <li class="loginOut"><router-link to="/LoginLogout"><i class="bi bi-person-circle"></i> Login Logout</router-link></li>
        <li class="ArtworksPre"><router-link to="/ArtworksPre"><i class="bi bi-person-circle"></i> ArtworksPre</router-link></li>
<!--        <li class="ArtworksDemo"><router-link to="/ArtworksDemo"><i class="bi bi-person-circle"></i> ArtworksDemo</router-link></li>-->


    </ul>
  </div>




  <div id="navBorder"></div>
</div>


  <router-view />

  <div class="spacer"></div>


</template>

<style>

/** {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*}*/
/*body {*/
/*    text-align: center;*/
/*    overflow: hidden!important;*/
/*    user-select: none;*/
/*}*/

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  /* min-height: 50vh; */
}

h1 {
  margin-top: 1em;
  margin-bottom: .5em;
}

ul {
  list-style-type: none;

  overflow: hidden;
  /* background-color: #333; */
}

li {
  float: left;
}

li a {
  display: block;
  font-weight: bold;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
}

/*
li a:hover {
  background-color: #111;
}
 */

#navWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  padding-top: 10px;
  background-color: white;
  /* border: 1px solid cyan; */
}

#nav {
  background-color: rgba(256, 256, 256, .8);
  display: flex;
  justify-content: center;
  /* padding-bottom: 80px;
  border: 1px solid lime; */
}
#navBorder {
  width: 100%;
  height: 5px;
  background-color: rgba(256, 256, 256, .5);
  /* border: 1px solid magenta; */
}

.addContent {
  border-bottom: 2px solid #ffcd3a;
}
.addContent > a {
  color: #886701;
}
.addContent:hover {
  background-color: #fff9e5;
}
.addContent > .router-link-active {
  background-color: #fff5d8;
}


.contentManagement {
  border-bottom: 2px solid #cb3837;
}
.contentManagement > a {
  color: #cb3837;
}
.contentManagement:hover {
  background-color: #faebeb;
}
.contentManagement > .router-link-active {
  background-color: #f5d7d7;
}


.ticketManagement {
  border-bottom: 2px solid #c836c3;
}
.ticketManagement > a {
  color: #782175;
}
.ticketManagement:hover {
  background-color: #faebf9;
}
.ticketManagement > .router-link-active {
  background-color: #f4d7f3;
}


.userAccManagement {
  border-bottom: 2px solid #8956ff;
}
.userAccManagement > a {
  color: #29008a;
}
.userAccManagement:hover {
  background-color: #ede5ff;
}
.userAccManagement > .router-link-active {
  background-color: #e7ddff;
}


.loginOut {
  border-bottom: 2px solid #146a90;
}
.loginOut > a {
  color: #146a90;
}
.loginOut:hover {
  background-color: #e9f6fc;
}
.loginOut > .router-link-active {
  background-color: #d4eef9;
}
.ArtworksDemo.body {
  text-align: center;
    overflow: hidden!important;
    user-select: none;
}



.npmColors {

  color: #886701; /* Text */
  color: #ffcd3a; /* Border */
  color: #fff9e5; /* Hover */
  color: #fff5d8; /* Active */

  color: #cb3837; /* Text */
  color: #cb3837; /* Border */
  color: #faebeb; /* Hover */
  color: #f5d7d7; /* Active */

  color: #782175; /* Text */
  color: #c836c3; /* Border */
  color: #faebf9; /* Hover */
  color: #f4d7f3; /* Active */

  color: #29008a; /* Text */
  color: #8956ff; /* Border */
  color: #ede5ff; /* Hover */
  color: #e7ddff; /* Active */

  color: #146a90; /* Text */
  color: #29abe2; /* Border */
  color: #e9f6fc; /* Hover */
  color: #d4eef9; /* Active */

}




.viewsContainer {
  width: 100%;
  margin-top: 150px;
  display: flex;
  justify-content: center;

}
.viewsContainer2 {
  max-width: 600px;
  display: block;
}
.spacer {
  height: 20vh;
}

.SVartworkInfo {
  font-size: .8em;
  color: #888;
  text-decoration: none;

}

.SVartworkInfoVideoLink {
  font-size: .8em;
  color: limegreen;
  text-decoration: none;
}

.SVredText {
  color: red;
}

.SVredErrorBorder {
  border-color: red;
}

.SVgreenValidBorder {
  border-color: green;
}

.form-control {
  width: 100%!important;
}
.card {
  width: 500px;
  margin-bottom: 5px!important;
}
.card-title-SVForm {
  text-align: left!important;
}
h6.svVersionNumber {
  color:darkgray;
  position:relative;
  top:-30px;
  left:180px;
  font-size:1em;
}

</style>
