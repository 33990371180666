import axios from 'axios'
import { alert } from "@/views/_helpers/bootstrapAlert";
import { path } from "@/_helpers/basepath";

export async function upload_video() {
  let ret = false;
  let userName = JSON.parse(localStorage.getItem("storedUserName"));
  let userPass = JSON.parse(localStorage.getItem("storedUserPass"));

  const config = { headers: { 'Content-Type': 'multipart/form-data' } }
  const data = new FormData(document.getElementById('uploadVideoForm'))
  var videofile = document.querySelector('#newVideo')
  try {
    console.log('UPLOAD VIDEO :)', videofile.files[0])
    data.append('file', videofile.files[0])
    data.append('filename', videofile.files[0].name)

  }catch (error){
   console.log('no video File')
  }


  await axios.post(path() + '/api/upload/vid', data,
    {
      auth: {
        username: userName,
        password: userPass,
      }
    },
    config,
  )
    .then(response => {
      if (response.status === 200) {
        console.log("Response: ", response.data.message);
        alert('Server Response:<br><b>' + response.status + ': ' + response.data.message + '<b>', 'success', 'bi-check-circle-fill');
        ret = true;
      }

    }).catch(err => {
      console.log('catch video upload', err.status, err.response.data.message);
      alert('Server Response:<br><b>' + err.status + ': ' + err.response.data.message + '<b>', 'danger', 'exclamation-triangle-fill');
    });
  return ret;
}