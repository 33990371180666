<template>


  <div class="viewsContainer">
    <div class="viewsContainer2">

      <h1 style="color:#146a90;">Delete Artist</h1>


      <div class="card">

        <div class="card-body">
          <form @submit.prevent="onSubmit">

            <h5 class="card-title-SVForm">Choose Artist to delete:</h5>

            <!-- deleteArtist -->
            <select id="confirmDeleteArtistTest2" class="form-select" aria-label="Default select example"
              style="margin-bottom: 2em;" v-model="v$.form.confirmDeleteArtistTest2.$model">

              <option v-for="post in posts" :key="post.id" v-bind:value="post.name">{{ post.name }}</option>
            </select>



            <!-- confirmDeleteArtist -->
            <div class="mb-3 mt-3">
              <div class="form-group">
                <h5 class="card-title-SVForm">Confirm Deletion:</h5>
                <input id="confirmDeleteArtist" class="form-control" placeholder="Type Artist Name here to confirm deletion"
                  type="text" v-model="v$.form.confirmDeleteArtist.$model">
                <!-- Error Message -->
                <div class="input-errors" v-for="(error, index) of v$.form.confirmDeleteArtist.$errors" :key="index">
                  <div class="error-msg SVredText">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <div id="liveAlertPlaceholder"></div>

            <!-- Submit Button -->
            <div class="mb-3 mt-3">
              <button class="btn btn-primary" :disabled="v$.form.$invalid" v-on:click="deleteArtist()">Delete
                Artist</button>
            </div>

          </form>

        </div>
      </div>


    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { alert } from "@/views/_helpers/bootstrapAlert";
import {path} from "@/_helpers/basepath";


import useVuelidate from '@vuelidate/core'
import { required, sameAs, helpers } from '@vuelidate/validators'


export default {

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      form: {
        confirmDeleteArtistTest2: '',
        confirmDeleteArtist: '',
      },
      posts: [],
    }
  },

  validations() {
    return {
      form: {
        confirmDeleteArtistTest2: {
          required
        },
        confirmDeleteArtist: {
          required,
          sameAs: helpers.withMessage('Artist Names don\'t match!', sameAs(this.form.confirmDeleteArtistTest2))
        }
      },
    }
  },

  mounted() {

    var usrname = JSON.parse(localStorage.getItem("storedUserName"));
    var usrpass = JSON.parse(localStorage.getItem("storedUserPass"));

    axios
      .get(path() + '/api/art/', {
        auth: {
          username: usrname,
          password: usrpass,
        }
      },
        console.log('Authenticated'),
      ).then((response) => {
        this.posts = response.data
      })

  },


  methods: {

    chooseArtist() {
      let chosenArtistName = document.getElementById("deleteArtist").value;
      document.getElementById("confirmDeleteArtistTest2").value = chosenArtistName;
    },



    deleteArtist() {

      let userName = JSON.parse(localStorage.getItem("storedUserName"));
      let userPass = JSON.parse(localStorage.getItem("storedUserPass"));
      let artistName = document.getElementById("confirmDeleteArtist").value;

      axios.post(

        path() + '/api/artist/delete_artist',

        { name: artistName },

        {
          auth: {
            username: userName,
            password: userPass,

          }
        }
      )

        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            alert(error.response.data.message, 'danger', 'exclamation-triangle-fill');
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        })

        .then(response => {
          if (response.status === 200) {
            // console.log("Artist deleted: ", artistName);
            console.log("Response: ", response.data.message);
            // alert('Artist deleted: ' + artistName + response.data.message, 'success', 'info-circle-fill');
            alert(response.data.message, 'success', 'info-circle-fill');
          }
          else {
            console.log("ELSE Response: ", response);
            console.log("ELSE Artist not deleted: ", artistName);
            alert('ELSE Artist not deleted: ' + artistName, 'secondary', 'info-circle-fill');
          }
        })
        .catch(err => {
          console.log("ERR Artist not deleted :( - " + err, artistName);
          alert('ERR Artist not deleted :(', 'danger', 'exclamation-triangle-fill');
        });
    },
  }, // END METHODS
}
</script>