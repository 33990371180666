<template>

 <div class="viewsContainer">
 <div class="viewsContainer2">
 <div id="scene">
        <div id="gallery">
            <img src="https://i.pinimg.com/474x/24/b4/d9/24b4d97ecb74b8fd805490c4e2002544--funny-gifs-funny-memes.jpg"
                alt="1" class="gallery-img" />
            <img src="https://i.pinimg.com/originals/79/b4/ba/79b4bafd01336f7261827e55e8c32ed0.jpg" alt="2"
                class="gallery-img" />
            <img src="https://i0.kym-cdn.com/photos/images/newsfeed/001/057/345/3ce.jpg" alt="3" class="gallery-img" />
            <img src="https://i.pinimg.com/originals/8f/0b/19/8f0b19b7ed4a061940d45e30be555b51.jpg" alt="4"
                class="gallery-img" />
            <img src="https://i.pinimg.com/originals/e4/8e/77/e48e77151d2d4e863e5062674ceb8947.jpg" alt="5"
                class="gallery-img" />
            <img src="https://i.pinimg.com/736x/50/b1/98/50b1980369c86587afee9b7d34b55488.jpg" alt="6"
                class="gallery-img" />
            <img src="https://i.pinimg.com/736x/63/60/c2/6360c29eccd3931536123c3f290af103--lisa-s-mona-lisa.jpg" alt="7"
                class="gallery-img" />
            <img src="https://i.pinimg.com/originals/6b/33/75/6b3375240c30d2fa9f0a324ee79e38e9.jpg" alt="8"
                class="gallery-img" />
        </div>
    </div>


    <div id="gallery-nav">
        <button id="prev-btn">Previous</button>
        <button id="next-btn">Next</button>
    </div>
  </div>
 </div>

</template>

<script>


import axios from "axios";
import {path} from "@/_helpers/basepath";

export default {
  name: "ArtworksDemo",
  data(){
    return {
      posts: [],
      artworksin: [],
      artistKey: 0,
      artworkKey: 0,
    }
    },
  mounted() {
    this.get_data()
     const initGallery = () => {
            const gallery = document.querySelector('#gallery');
            const galleryImgs = gallery.querySelectorAll('.gallery-img');
            const numImgs = galleryImgs.length;
            const frameWidth = gallery.offsetWidth;
            const relativeImgAngle = -360 / numImgs;
            const radius = -Math.round(frameWidth / 2 / Math.tan(Math.PI / numImgs));
            let currentIndex = 0;

            galleryImgs.forEach((galleryImg, index) => {
                const imgAngle = relativeImgAngle * index;
                galleryImg.style.transform = `rotateY(${imgAngle}deg) translateZ(${radius}px)`;
            });

            const rotateGallery = () => {
                const galleryAngle = relativeImgAngle * currentIndex;
                gallery.style.transform = `translateZ(${-3 * radius}px) rotateY(${galleryAngle}deg)`;
            };

            const prevButton = document.querySelector("#prev-btn");
            prevButton.addEventListener('click', () => {
                ++currentIndex;
                rotateGallery();
            });

            const nextButton = document.querySelector("#next-btn");
            nextButton.addEventListener('click', () => {
                --currentIndex;
                rotateGallery();
            });

            rotateGallery();
        };

        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', initGallery);
        } else {
            initGallery();
        }
  },
  methods: {
    get_data(){
      let usrname = JSON.parse(localStorage.getItem("storedUserName"));
      let usrpass = JSON.parse(localStorage.getItem("storedUserPass"));

    axios
      .get(path() + '/api/art/', {
        auth: {

          username: usrname,
          password: usrpass,
        }
      },
      ).then((response) => {
        let li = response.data;
        let li_new = []
        for (let e in li ){
          if (li[e]['deleted'] === false){
            console.log(li[e]);
            for (let i in li[e]['artworks']){
            //delete li[e]
              if (li[e]['artworks'][i]['deleted'] === false && li[e]['artworks'][i]['active'] === true){
                  li_new.push(li[e]['artworks'][i])
              }
            }
          }
        }
        console.log('new li: ', li_new)
        //this.posts = response.data;
        this.posts = li_new;


      })

    },
    async image_get(url, id) {
      let usrname = JSON.parse(localStorage.getItem("storedUserName"));
      let usrpass = JSON.parse(localStorage.getItem("storedUserPass"));

      axios
        .get(url, {
          responseType: "blob",
          auth: {
            username: usrname,
            password: usrpass,
          }
        },
        ).then(function (response) {
          var reader = new window.FileReader();
          reader.readAsDataURL(response.data);
          console.log("date_n: ", reader)
          reader.onload = function () {
            let imageDataUrl = reader.result;


            console.log(id)
            document.getElementById(id).setAttribute("src", imageDataUrl);
          }
          return {}
        }).catch(err => {
          console.log("WRONG CREDENTIALS - USER NOT LOGGED IN! NO IMAGE WILL BE SHOWN! :( - " + err);
        });
    },



}
}

</script>

<style scoped>
* {
  box-sizing: border-box;

}

body{
    text-align: center;
    overflow: hidden!important;
    user-select: none;

}
#scene {
    /*border: 1px solid lightgrey;*/
    position: absolute;
    width: 210px;
    height: 140px;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    perspective: 1000px;
}

#gallery {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 1s;
}

.gallery-img {
    position: absolute;
    width: 190px;
    height: 120px;
    left: 10px;
    top: 10px;
    border: 2px solid black;
    transition: transform 1s;
}

#gallery-nav {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}


</style>