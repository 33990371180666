<template>


  <div class="viewsContainer">
    <div class="viewsContainer2">

      <h1 style="color:#146a90;">New Artwork</h1>

      <div class="card">
        <form @submit.prevent="onSubmit">
        <div class="card-body">

          <h5 class="card-title" style="text-align: left">Artist</h5>

          <select id="chooseArtist" class="form-select" aria-label="Default select example" required style="margin-bottom: 2em;">
            <option value="">Choose Artist</option>
            <!-- <option v-for="post in posts" :key="post.id" value="post.name">{{ post.name }}</option> -->
            <option v-for="post in posts" :key="post.id" v-bind:value="post.name">{{ post.name }}</option>
          </select>

          <h5 class="card-title" style="text-align: left">Secondary Artist</h5>
          <select id="chooseSecondaryArtist" class="form-select" aria-label="Default select example"
            style="margin-bottom: 2em;">
            <option value="">Choose Artist</option>
            <option></option>
            <!-- <option v-for="post in posts" :key="post.id" value="post.name">{{ post.name }}</option> -->
            <option v-for="post in posts" :key="post.id" v-bind:value="post.name">{{ post.name }}</option>
          </select>

          <h5 class="card-title" style="text-align: left">Title</h5>
          <div class="input-group mb-3">
            <input id="newArtworkTitle" type="text" class="form-control" required>
          </div>

          <h5 class="card-title" style="text-align: left">Video (Upload)</h5>
          <form class="row g-2" id="uploadVideoForm" name="uploadVideoForm" enctype="multipart/form-data">
            <div class="col-auto">
              <input class="form-control" type="file" id="newVideo" name="newVideo" style="margin-bottom: 20px">
            </div>

          </form>

          <h5 class="card-title" style="text-align: left">Image (Upload)</h5>
          <form class="row g-2" id="uploadForm" name="uploadForm" enctype="multipart/form-data">
            <div class="col-auto">
              <input class="form-control" type="file" id="newImage" name="newImage" style="margin-bottom: 20px">
            </div>
          </form>


          <h5 class="card-title" style="text-align: left">Courtesy</h5>
          <div class="input-group mb-3">
            <input id="newArtworkCourtesy" type="text" class="form-control">
          </div>

          <h5 class="card-title" style="text-align: left">Duration</h5>
          <div class="input-group mb-3">
            <input id="newArtworkDuration" type="text" class="form-control">
          </div>

          <h5 class="card-title" style="text-align: left">Medium</h5>
          <div class="input-group mb-3">
            <input id="newArtworkMedium" type="text" class="form-control">
          </div>

          <h5 class="card-title" style="text-align: left">Year</h5>
          <div class="input-group mb-3">
            <input id="newArtworkYear" type="text" class="form-control">
          </div>

          <h5 class="card-title" style="text-align: left">Description</h5>
          <div class="input-group mb-3">
            <textarea id="newArtworkDescription" type="text" class="form-control" aria-label="Biography"
              aria-describedby="basic-addon1"></textarea>
          </div>
          <div id="SpinnerPlaceholder" style="padding-left: 36%; padding-right: 30%; display: none">
            <tr>
              <td><h6 style="padding-left: 10px; padding-bottom: 10%">Upload data </h6></td>
              <atom-spinner
                            :animation-duration="1000"
                            :size="60"
                            :color="'#234AB3'"
              />
            </tr>

          </div>
          <div id="liveAlertPlaceholder"></div>

          <button id="save_btn" class="btn btn-primary" v-on:click="save()">Save</button>
        </div>
        </form>

        <p id="demo"></p>

      </div>
    </div>
  </div>

</template>


<script>
import axios from 'axios'
import { alert } from "@/views/_helpers/bootstrapAlert";
import {path} from "@/_helpers/basepath";
import {upload_video} from "@/_helpers/videoUpload";
import {upload_picture} from "@/_helpers/imageUpload";
import {create_artwork} from "@/_helpers/createArtwork";
import {AtomSpinner} from 'epic-spinners'



export default {
  components: {
      AtomSpinner,
    },
  data() {
    return {
      posts: [],
    }
  },
  mounted() {

    var usrname = JSON.parse(localStorage.getItem("storedUserName"));
    var usrpass = JSON.parse(localStorage.getItem("storedUserPass"));

    axios
      .get(path() + '/api/art/', {
        auth: {

          username: usrname,
          password: usrpass,
        }
      },
        console.log('Authenticated'),
      ).then((response) => {

        this.posts = response.data

      })
  },


  methods: {

    async save() {
      document.getElementById('SpinnerPlaceholder').style.display = 'block'
      document.getElementById('save_btn').disabled = true
      if (await upload_video() && await upload_picture() === true) {
        create_artwork();
        console.log('video und image fertig');
        this.resetFields()
        document.getElementById('SpinnerPlaceholder').style.display = 'none'
        document.getElementById('save_btn').disabled = false
      }
      else {
        alert('FAILED :()');
        document.getElementById('SpinnerPlaceholder').style.display = 'none'
        document.getElementById('save_btn').disabled = false
      }

    },
    resetFields(){
    let inputArray = document.querySelectorAll('input');
    inputArray.forEach(function (input){
        input.value = "";
    });

    let textAreaArray = document.querySelectorAll('textarea');
    textAreaArray.forEach(function (textarea){
        textarea.innerText = "";
    });

    let  textAreaFe = document.getElementById('newArtworkDescription');
    textAreaFe.value = '';

},
  }, // END METHODS
}
</script>