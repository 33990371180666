<template>


  <div class="viewsContainer">
    <div class="viewsContainer2">


      <h1 style="color:#146a90;">New Artist</h1>
      <h6 class="svVersionNumber">v0.0.2</h6>

      <div class="card">
        <div class="card-body">

          <form @submit.prevent="onSubmit" id="NewArtist">

            <!-- newArtistName -->
            <!-- newArtistCover -->
            <!-- newArtistBiography -->
            <!-- newArtistWebsite -->
            <!-- newArtistInstagram -->


            <!-- newArtistName -->
            <div class="mb-3 mt-3">
              <div class="form-group">
                <h5 class="card-title-SVForm">Artist Name:</h5>
                <input id="newArtistName" class="form-control" placeholder="Enter Artist Name" type="text"
                  v-model.lazy="v$.form.newArtistName.$model" required
                  v-bind:class="{ 'SVredErrorBorder': v$.form.newArtistName.$invalid }">
                <div class="pre-icon os-icon os-icon-user-male-circle"></div>
                <!-- Error Message -->
                <div class="input-errors" v-for="(error, index) of v$.form.newArtistName.$errors" :key="index">
                  <div class="error-msg SVredText">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <!-- newArtistCover -->
            <div class="mb-3 mt-3">
              <div class="form-group">
                <h5 class="card-title-SVForm">Quote:</h5>
                <textarea id="newArtistCover" class="form-control" placeholder="Enter Quote" type="text"
                  v-model="v$.form.newArtistCover.$model"></textarea>
                <!-- Error Message -->
                <div class="input-errors" v-for="(error, index) of v$.form.newArtistCover.$errors" :key="index">
                  <div class="error-msg SVredText">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <!-- newArtistBiography -->
            <div class="mb-3 mt-3">
              <div class="form-group">
                <h5 class="card-title-SVForm">Biography:</h5>
                <textarea id="newArtistBiography" class="form-control" placeholder="Enter Biography" type="text"
                  v-model="v$.form.newArtistBiography.$model"></textarea>
                <!-- Error Message -->
                <div class="input-errors" v-for="(error, index) of v$.form.newArtistBiography.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <!-- newArtistWebsite -->
            <div class="mb-3 mt-3">
              <div class="form-group">
                <h5 class="card-title-SVForm">Website:</h5>
                <input id="newArtistWebsite" class="form-control" placeholder="Enter Website" type="text"
                  v-model="v$.form.newArtistWebsite.$model">
                <!-- Error Message -->
                <div class="input-errors" v-for="(error, index) of v$.form.newArtistWebsite.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <!-- newArtistInstagram -->
            <div class="mb-3 mt-3">
              <div class="form-group">
                <h5 class="card-title-SVForm">Instagram:</h5>
                <input id="newArtistInstagram" class="form-control" placeholder="Enter Instagram" type="text"
                  v-model="v$.form.newArtistInstagram.$model">
                <!-- Error Message -->
                <div class="input-errors" v-for="(error, index) of v$.form.newArtistInstagram.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <!-- newArtistTwitter -->
            <div class="mb-3 mt-3">
              <div class="form-group">
                <h5 class="card-title-SVForm">Twitter:</h5>
                <input id="newArtistTwitter" class="form-control" placeholder="Enter Twitter" type="text"
                  v-model="v$.form.newArtistTwitter.$model">
                <!-- Error Message -->
                <div class="input-errors" v-for="(error, index) of v$.form.newArtistTwitter.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <div id="liveAlertPlaceholder"></div>

            <!-- Submit Button -->
            <!-- <div class="buttons-w"> -->
            <div class="mb-3 mt-3">
              <button class="btn btn-primary" :disabled="v$.form.$invalid" v-on:click="createNewArtist()">Create
                Artist</button>
            </div>

          </form>

        </div>
      </div>



    </div>
  </div>

</template>


<script>
import axios from 'axios'
import { alert } from "@/views/_helpers/bootstrapAlert";
import {path} from "@/_helpers/basepath";
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z0-9&]+(?:[-'\\s][a-zA-Z0-9&]+)*$");
  return validNamePattern.test(name);
}
export default {
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        newArtistName: '',
        newArtistCover: '',
        newArtistBiography: '',
        newArtistWebsite: '',
        newArtistInstagram: '',
        newArtistTwitter: '',
      },
      posts: [],
      emptyForm: {},
    }
  },
  validations() {
    return {
      form: {
        newArtistName: {
          required,
          name_validation: {
            $validator: validName,
            $message: ''
          },
          min: minLength(2)
        },
        newArtistCover: {
        },
        newArtistBiography: {
        },
        newArtistWebsite: {
        },
        newArtistInstagram: {
        },
        newArtistTwitter: {
        },
      },
    }
  },
  mounted() {
    this.emptyForm = {...this.form}
  },
  methods: {
    resetForm(){
      this.form = {...this.emptyForm}
    },
    createNewArtist() {
      let userName = JSON.parse(localStorage.getItem("storedUserName"));
      let userPass = JSON.parse(localStorage.getItem("storedUserPass"));
      let artistName = document.getElementById("newArtistName").value;
      let artistCover = document.getElementById("newArtistCover").value;
      let artistBiography = document.getElementById("newArtistBiography").value;
      let artistWebsite = document.getElementById("newArtistWebsite").value;
      let artistInstagram = document.getElementById("newArtistInstagram").value;
      // Twitter
      let artistTwitter = document.getElementById("newArtistTwitter").value;
      axios.post(
        path() + '/api/artist/',
        { name: artistName, cover: artistCover, biography: artistBiography, website: artistWebsite, instagram: artistInstagram, twitter: artistTwitter },
        {
          auth: {
            username: userName,
            password: userPass,
          }
        }
      )
        .then(response => {
          if (response.status === 201) {
            console.log("Artist created: ", artistName);
            console.log("Response: ", response.data.message);
            alert('Artist created: ' + artistName, 'success', 'info-circle-fill');
            this.resetForm();
          }
        }).catch(err => {
          console.log("Artist NOT created ", artistName, err);
          console.log("ERROR RESPONSE DATA MESSAGE: " + err.response.data.message);
          alert('Server Response:<br><b>' + err.response.data.message + '<b>', 'danger', 'exclamation-triangle-fill');
        });
    },
  }, // END METHODS
}
</script>